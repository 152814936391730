import React from 'react'

import comedora from "../img/comedora.png";
import granada from "../img/granada.png";
import guasasapo from "../img/guasasapo.png";
import guasasapotv from "../img/gtv.png";
import trincheta from "../img/trincheta.png";

const Granada = () => {
    return(
        <div className="home">
        <div className="himg">
          <img src={guasasapo} alt="logo guasasapo"></img>
          <img src={comedora} alt="logo comedora"></img>
          <img src={trincheta} alt="logo trincheta"></img>
          <img src={granada} alt="logo granada"></img>
  
          <img src={guasasapotv} alt="logo guasasapotv"></img>
        </div>
      </div>
    );
    
}

export default Granada
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "moment";
import prev from "../../img/prev.png";
import next from "../../img/next.png";
import { CSSTransition } from "react-transition-group";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Social from './Social'
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import SimpleModal from "../Modal";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const site = "https://api.guasasapo.cl";
const ItemSeries = ({
    nombre,
    subcategoria,
    codigo,
    masinfo,
    descpricion,
    lanzamiento,
    imgdesc,
    duracion,
    trailer,
    temporadas
}) => {
    const [playing, setPlaying] = useState(false);
    const [compartira, setCompartira] = useState();
    const [masidata, setMasidata] = useState([]);
    const [tempS, setTempS] = useState([])
    const player = useRef(null);
    const [urlE, setUrlE] = useState();
    const [eCount, setECount] = useState(3);
    const [masi, setMasi] = useState(false);
    const classes = useStyles();
    const [temp, setTemp] = React.useState(1);
    const sliderE = useRef();

    useEffect(() => {
        setMasidata([]);
        setPlaying(false);
        setMasi(false);
        setTemp(1)
        let asd2 = masinfo.split("\n");
        asd2.map((i) => {
            let letx = i.split(": ");
            console.log(letx[0]);
            setMasidata((masidata) => [...masidata, { [letx[0]]: letx[1] }]);
        });
        console.log(masidata);
        var ii = temp - 1
        if (temporadas.length > 0) {
            axios.get(`https://api.guasasapo.cl/temporadas/${temporadas[ii].id}`).then(function (res) {
                setTempS(res.data.episodios);
                setECount(res.data.episodios.length)
                console.log(res.data.episodios);
            });
        }

    }, [nombre]);
    useEffect(() => {
        var ii = temp - 1
        if (temporadas.length > 0) {

            axios.get(`https://api.guasasapo.cl/temporadas/${temporadas[ii].id}`).then(function (res) {
                setTempS(res.data.episodios);
                setECount(res.data.episodios.length)
                console.log(res.data.episodios);
            });
        }
    }, [temp]);
    const playVideo = () => {
        setPlaying(true);
        console.log(player.current.wrapper);
        screenfull.request(player.current.wrapper);
    };
    document.addEventListener("fullscreenchange", onFullScreenChange, false);
    document.addEventListener(
        "webkitfullscreenchange",
        onFullScreenChange,
        false
    );
    document.addEventListener("mozfullscreenchange", onFullScreenChange, false);

    function onFullScreenChange() {
        var fullscreenElement =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement;
        console.log("asd");
        // if in fullscreen mode fullscreenElement won't be null
        if (fullscreenElement == null) {
            setPlaying(false);
        }
    }
    const handleChange = (event) => {
        setTemp(event.target.value);
    };

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: eCount > 4 ? 5 : eCount,
        draggable: false,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: eCount > 3 ? 4 : eCount,
                    slidesToScroll: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sNext = () => {
        sliderE.current.slickNext();
    };
    const sPrev = () => {
        sliderE.current.slickPrev();
    };
    const playEpisodio = url => {
        setUrlE(url)
        setPlaying(true)
        screenfull.request(player.current.wrapper)
    }
    return (
        <>
            <div className="pelidetalles" id="pelidetalles">
                <div className="info">
                    <div>
                        <div>
                            <h3>{subcategoria}</h3>
                            <div className={`linea Series`}></div>
                        </div>
                        <h1>{nombre}</h1>
                        <div className="spetalle">
                            <p>
                                <b>{codigo}</b> /{" "}
                                <span className="ano">{Moment(lanzamiento).format("YYYY")}</span>
                                <span className="ano" style={{ marginLeft: "10px" }}>
                                {duracion===null?null:`${duracion} MINUTOS`}              </span>
                            </p>
                        </div>
                        <p className="desc">{descpricion}</p>
                        <div
                            className="masinfo"

                        //onClick={() => setMasi(!masi)}
                        >
                            <p
                                onMouseEnter={() => setMasi(!masi)}
                                onMouseLeave={() => setMasi(!masi)}
                            >
                                + más info
            </p>
                        </div>
                        <div className="btnportada">
                            <Social nombre={nombre}></Social>

                            {trailer ? <SimpleModal t={trailer} tipo="trailer"></SimpleModal> : null}
                        </div>
                        <ReactPlayer
                            playing={playing}
                            key={nombre + codigo}
                            ref={player}
                            style={playing ? null : { display: "none" }}
                            config={{
                                vimeo: {
                                    playerOptions: { playsinline: false },
                                },
                            }}
                            controls={true}
                            url={urlE}
                        />
                    </div>
                </div>

                <div
                    className="foto"
                    style={{
                        backgroundImage: `url(https://api.guasasapo.cl${imgdesc})`,
                    }}
                >
                    <CSSTransition className="fotoinfo" timeout={200} in={masi}>
                        <div>
                            <div className="fitext">
                                {masidata.map((i) => {
                                    return (
                                        <div className="fittxt">
                                            <p className="ano">{Object.keys(i)}:</p>
                                            <p>{Object.values(i)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            <div className="slidertemporadas">
                <FormControl className={classes.formControl} style={temporadas.length > 0 ? null : { display: "none" }}>

                    <Select
                        value={temp}
                        onChange={handleChange}
                        className={classes.selectEmpty + " se"}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >

                        {temporadas.map(i => {
                            return (<MenuItem value={i.numtemporada}>TEMPORADA {i.numtemporada}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
                <div className="flechita prev fep" onClick={() => sPrev()} style={temporadas.length > 0 ? null : { display: "none" }}>
                    <img src={prev}></img>
                </div>
                <div className="flechita next fep" onClick={() => sNext()} style={temporadas.length > 0 ? null : { display: "none" }}>
                    <img src={next}></img>
                </div>
                <Slider
                    {...settings}
                    className="sliderepisodios"
                    ref={sliderE}
                    onInit={() => setTimeout(() => { sliderE.current.slickGoTo(0) }, 400)}
                    style={temporadas.length > 0 ? { height: "190px", marginLeft: "30px" } : { display: "none" }}
                >
                    {tempS.length > 1 ? tempS.map(i => {

                        return (
                            <SimpleModal img={site + i.imagen.url} t={i.URL} i={i} temp={temp} tipo="episodio"></SimpleModal>

                        )
                    }) : null}
                </Slider>

            </div>
        </>
    );
};

const Series = () => {
    const [ser, setSer] = useState([]);
    const [s, setS] = useState();
    const sliderS = useRef();
    const serieR = useRef();

    const scrollToRef = (ref) =>
        window.scrollTo(0, ref.current.offsetParent.offsetTop);
    useEffect(() => {
        axios.get(`https://api.guasasapo.cl/series?_sort=lanzamiento:DESC`).then(function (res) {
            setSer(res.data);
            console.log(res.data);
        });
    }, []);
    const selectP = (i) => {
        setS(i);
        scrollToRef(serieR);
        console.log(serieR);
    };
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: ser.length > 5 ? 5 : ser.length,
        draggable: false,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: ser.length > 5 ? 4 : ser.length,
                    slidesToScroll: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sNext = () => {
        sliderS.current.slickNext();
    };
    const sPrev = () => {
        sliderS.current.slickPrev();
    };

    return (
        <div className="sliderpeliculas" id="Peliculas">
            <h1>Series</h1>
            <div style={{ position: "relative" }}>
                <div className="flechita prev" onClick={() => sPrev()}>
                    <img src={prev}></img>
                </div>
                <div className="flechita next" onClick={() => sNext()}>
                    <img src={next}></img>
                </div>


                <Slider
                    {...settings}
                    ref={sliderS}
                    onInit={() => setTimeout(() => { sliderS.current.slickGoTo(0) }, 400)}

                >
                    {ser.map((i) => {
                        return (
                            <div className="peli">
                                <img
                                    alt={i.nombre}
                                    src={site + i.img.url}
                                    onClick={() => selectP(i)}
                                    key={i.codigo}
                                ></img>
                            </div>
                        );
                    })}
                </Slider>

            </div>
            <div ref={serieR}>
                {s ? (
                    <CSSTransition
                        className="detallespeli"
                        timeout={200}
                        in={s}
                        appear={true}
                    >
                        <div>
                            <ItemSeries
                                nombre={s.nombre}
                                subcategoria={s.subcategoria}
                                codigo={s.codigo}
                                lanzamiento={s.lanzamiento}
                                trailer={s.trailer}
                                masinfo={s.masinfo}
                                descpricion={s.descripcion}
                                duracion={s.duracion}
                                imgdesc={s.img2descripcion.url}
                                temporadas={s.temporadas}

                            ></ItemSeries>
                        </div>
                    </CSSTransition>
                ) : null}
            </div>
        </div>
    );
};

export default Series;

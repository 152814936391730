import React from "react";

//IMAGENES
import fb from "../img/footer/fb.png";
import ig from "../img/footer/ig.png";
import youtube from "../img/footer/youtube.png";
import logoguasasapo from "../img/footer/logoguasasapo.png";
import granada from "../img/footer/granada.png";
import comedora from "../img/footer/comedora.png";
import trincheta from "../img/footer/trincheta.png";
import guasasapotv from "../img/footer/guasasapotv.png";

const Footer = () => {
  return (
    <div className="footer">
      <div className="f1">
        <p>
          <b>GUASASAPO</b> cooperativa abierta<br></br> de arte y producción
        </p>
        <p>Barrio Villa Olímpica, Santiago de Chile</p>
        <div className="logossocial">
          <a href="https://www.facebook.com/guasasapo" target="_blank">
            <img src={fb} alt="link facebook"></img>
          </a>
          <a href="https://www.instagram.com/guasasapo/" target="_blank">
            <img src={ig} alt="link instagram"></img>
          </a>
          <a
            href="https://www.youtube.com/channel/UCBcXgu-KiMKsyw9LUDYCFUw"
            target="_blank"
          >
            <img src={youtube} alt="link youtube"></img>
          </a>
        </div>
      </div>
      <div className="f2">
        <img src={logoguasasapo} alt="logo guasasapo"></img>
        <div>
          <p>
            |<a>Acceso y Uso</a>|<a>Funcionamiento y autogestión</a>|
            <a>
              Cómo apoyar <b>GUASASAPOTV</b>
            </a>
            |
          </p>
        </div>
      </div>
      <div className="f3">
        <a href="/comedora">
          <img src={comedora}></img>
        </a>
        <a href="/trincheta">
          <img src={trincheta}></img>
        </a>
        <a href="/granada">
          <img src={granada}></img>
        </a>
        <a href="/guasasapotv">
          <img src={guasasapotv}></img>
        </a>
      </div>
    </div>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";

import Slider from "react-slick";
import axios from "axios";
import Moment from "moment";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import ModalIntro from '../components/ModalIntro'
import logo from "../img/guasasapotv.png";
import prev from "../img/prev.png";
import colaboradores from "../img/colaboradores2.png";
import colaborador from "../img/colaboradores.png";

import trailer from "../img/trailer.png";
import play from "../img/play.png";
import compartir from "../img/compartir.png";
import next from "../img/next.png";
import Footer from "./Footer";

import Peliculas from "./Peliculas";
import Series from "./Series"
import Graficayfoto from "./Graficayfoto"
import SimpleModal from "./Modal";

import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Arteses from "./Arteses";
import Artesvi from "./Artesvi";
import Escritura from "./Escritura";
import Audioymusica from "./Audioymusica";
import Autoeducacion from "./Autoeducacion"
const ItemSlide = ({
  categoria,
  lanzamiento,
  nombre,
  URL,
  descripcion,
  fondo,
  duracion,
  codigo,
  trailer
}) => {
  useEffect(() => {
    setPlaying(false);
  }, []);
  const playVideo = (x) => {
    setPlaying(true);
    screenfull.request(player.current.wrapper);
  };
  const [compartira, setCompartira] = useState();

  const [playing, setPlaying] = useState(false);
  const player = useRef();
  const handleShare = (x) => {
    compartira == x ? setCompartira(false) : setCompartira(x);
  };
  document.addEventListener("fullscreenchange", onFullScreenChange, false);
  document.addEventListener(
    "webkitfullscreenchange",
    onFullScreenChange,
    false
  );
  document.addEventListener("mozfullscreenchange", onFullScreenChange, false);

  function onFullScreenChange() {
    var fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement;
    console.log("asd");
    // if in fullscreen mode fullscreenElement won't be null
    if (fullscreenElement == null) {
      setPlaying(false);
    }
  }
  return (
    <div className="ipt">
      <div
        className="isportada"
        style={{
          backgroundImage: `url(https://api.guasasapo.cl${fondo})`,
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="portadacont">
          <div style={{ height: "fit-content" }}>
            <h2>{categoria}</h2>
            <div className={`linea ${categoria}`}></div>
          </div>
          <h1> {nombre}</h1>
          <div className="spdetalle">
            <p>
              <b>{codigo}</b> /{" "}
              <span className="ano">{Moment(lanzamiento).format("YYYY")}</span>
              <span className="ano" style={{ marginLeft: "10px" }}>
              {duracion===null?null:`${duracion} MINUTOS`}
              </span>
            </p>
          </div>
          <p>{descripcion}</p>
          <div className="btnportada">
            {URL ? <SimpleModal t={URL} tipo={"play"}></SimpleModal> : null}

            <div className="ctnportada" onClick={() => handleShare(nombre)}>
              <img src={compartir}></img>
              <p>COMPARTIR</p>
              <div
                className="sharewindow"
                style={compartira ? null : { display: "none" }}
              >
                <WhatsappShareButton
                  url={"www.guasasapo.cl/guasasapotv"}
                  title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                  separator=" - "
                >
                  <WhatsappIcon round={true} size={40}></WhatsappIcon>
                </WhatsappShareButton>
                <FacebookShareButton
                  url={"www.guasasapo.cl/guasasapotv"}
                  quote={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                >
                  <FacebookIcon round={true} size={40}></FacebookIcon>
                </FacebookShareButton>
                <TwitterShareButton
                  url={"www.guasasapo.cl/guasasapotv"}
                  title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                >
                  <TwitterIcon round={true} size={40}></TwitterIcon>
                </TwitterShareButton>
                <EmailShareButton
                  url={"www.guasasapo.cl/guasasapotv"}
                  subject={`${nombre} en GUASASAPOTV`}
                  body={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                >
                  <EmailIcon round={true} size={40}></EmailIcon>
                </EmailShareButton>
              </div>
            </div>
            {trailer ? <SimpleModal t={trailer} tipo={"trailer"}></SimpleModal> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const Sliderportada = () => {
  const [i, setI] = useState();
  const slider = useRef();
  const [itemS, setItems] = useState([]);

  useEffect(() => {
    axios.get("https://api.guasasapo.cl/vprincipal").then(function (response) {
      let items = []
      setI(response.data);
      console.log(response.data)
      response.data.artesescenicas.map(i => {
        items.push(i)
      })
      response.data.artesvisuales.map(i => {
        items.push(i)
      })
      response.data.audioymusicas.map(i => {
        items.push(i)
      })
//      response.data.autoeducacions.map(i => {
 //       items.push(i)
 //     })
      response.data.escrituras.map(i => {
        items.push(i)
      })
      response.data.graficayfotografias.map(i => {
        items.push(i)
      })
      response.data.peliculas.map(i => {
        items.push(i)
      })
      response.data.series.map(i => {
        items.push(i)
      })
      setItems(items)
    });
  }, []);
  const sNext = () => {
    slider.current.slickNext();
  };
  const sPrev = () => {
    slider.current.slickPrev();
  };

  return (
    <div className="sportada">
      <div className="headerp">
        <div className="nextprev">
          <div onClick={() => sPrev()}>
            <img src={prev}></img>
          </div>
          <div onClick={() => sNext()} style={{ marginLeft: "5px" }}>
            <img src={next}></img>
          </div>
        </div>
        <div>
          <img src={logo} style={{ width: "300px" }} onClick={() => console.log(itemS)}></img>
        </div>
        <SimpleModal tipo={"colaboradores"}></SimpleModal>
      </div>
      <Slider
        ref={slider}
        autoplay={true}
        autoplaySpeed={12000}
        draggable={false}
        arrows={false}
        pauseOnHover={false}
        style={{ height: "100%", marginLeft: "0", paddingLeft: "0" }}
      >
        {itemS
          ? itemS.map((i) => {
            return (
              <ItemSlide
                nombre={i.nombre}
                categoria={i.categoria}
                URL={i.URL}
                lanzamiento={i.lanzamiento}
                duracion={i.duracion}
                fondo={i.img2descripcion.url}
                codigo={i.codigo}
                descripcion={i.descripcion}
                trailer={i.trailer}
              ></ItemSlide>
            );
          })
          : null}
      </Slider>
    </div>
  );
};

function GuasasapoTv() {
  return (
    <div className="App">
      <div className="portada"></div>
      <Sliderportada />
      <Peliculas />
      <Series />
      <Audioymusica />
      <Graficayfoto />
      <Arteses />
      <Artesvi />
      <Escritura />
      <Autoeducacion />
      <Footer />

      <ModalIntro />
    </div>
  );
}

export default GuasasapoTv;

import React from "react";
import "./App.sass";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import GuasasapoTv from "./components/Guasasapotv";
import Introtv from "./components/Introtv"
import comedora from "./img/comedora.png";
import granada from "./img/granada.png";
import guasasapo from "./img/guasasapo.png";
import guasasapotv from "./img/gtv.png";
import trincheta from "./img/trincheta.png";
import Granada from "./components/Granada";
import Trincheta from "./components/Trincheta";
import Comedora from "./components/Trincheta";

import Guasasapo from "./components/Guasasapo";
import Content from "./components/content/Content";

function Home() {
  return (
    <div className="home">
    <div className="himg">
      <img src={guasasapo} alt="logo guasasapo"></img>
      <img src={comedora} alt="logo comedora"></img>
      <img src={trincheta} alt="logo trincheta"></img>
      <img src={granada} alt="logo granada"></img>

      <a href="/guasasapotv"><img src={guasasapotv} alt="logo guasasapotv"></img></a>
    </div>
  </div>
  );
}

function App() {
  return (
    <Router>
      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/introtv">
          <Introtv />
        </Route>
        <Route path="/granada">
          <Granada />
        </Route>
        <Route path="/trincheta">
          <Trincheta />
        </Route>
        <Route path="/guasasapo">
          <Guasasapo />
        </Route>
        <Route path="/comedora">
          <Comedora />
        </Route>
        <Route path="/guasasapoTV">
          <GuasasapoTv />
        </Route>
        <Route path="/content">
          <Content></Content>
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import next from "../img/next.png";

import fondo from '../img/fondo2.png'

const Introtv = ({setOpen}) => {
    const [step, setStep] = useState(1)
    const handleX = () => {
      setOpen(false)
    }
    const nextStep = s => {
        setStep(s)
    }
    const renderSwitch = (step => {
        switch (step) {
            case 1:
                return (
                    <div className="itv modalintro">
                        <div className="itv modalintro">
                            <h1>Acceso y uso</h1>


                            <p><b>GUASASAPOTV</b> es la plataforma multimedia de libre acceso y difusión de los proyectos y
                                contenido creativo que desarrolla la cooperativa GUASASAPO.</p>

                            <p>Se fomenta a bajar, piratear, contrabandear y disfrutar de nuestro contenido con completa libertad, salvo usufructo mercantil, comercial o de uso social que no comparta los principios y horizontes de nuestra cooperativa.</p>
                        </div>
                        <img src={next} className="cursormodalintro" onClick={() => nextStep(step + 1)}></img>
                    </div>
                )
                break;
            case 2:
                return (
                    <div className="itv modalintro">

                        <div className="itvb modalintro">
                            <h1>Funcionamiento y autogestión</h1>


                            <p>Todos los proyectos, al igual que todo lo que hacemos como cooperativa,
                            son iniciativas autogestionadas y autodeterminadas, sin fondos del Estado u
                                 organizaciones privadas.</p>

                            <p>Los proyectos se generan en nuestro espacio creativo <b>Taller de obra</b> que
                                 funciona con periodicidad semanal y el cual es un espacio de
                                 trabajo horizontal y colectivo.</p>
                            <p>Todos los recursos generados por medio de estos proyectos y
                                sus ítems derivados van en un 100% a autigestionar los proyectos venideros.</p>

                        </div>
                        <img src={next} className="cursormodalintro" onClick={() => nextStep(step + 1)}></img>

                    </div>
                )
                break;
            case 3:
                return (
                    <div className="itv modalintro">

                        <div className="itvb modalintro">
                            <h1>Cómo apoyar GUASASAPOTV</h1>


                            <p>Puedes apoyar el desarrollo de estos y otros proyectos,
                            como también el trabajo de nuestro cooperativismo, comprando productos de
                                la <b>Comedora GUASASAPO</b> y prefiriendo los servicios y productos de
                                 <b> Trincheta</b> espacio gráfico y editorial y de <b>Granada</b> estudio de grabación y postproducción de sonido.
                                 Todas secciones de producción de nuestra cooperativa.</p>

                            <p>También si deseas puedes hacer tu aporte voluntario depositando a nuestra cuenta cooperativa: para esto escríbenos a nuestro correo <a href="mailto:contacto@guasasapo.cl">contacto@guasasapo.cl</a></p>
                        </div>
                        <img src={next} className="cursormodalintro" onClick={() => nextStep(step + 1)}></img>

                    </div>
                )
                break;
            case 4:

            return(
                <div className="itv modalintro">

                        <div className="itvb modalintro">
                            <h1>Contenidos del sitio</h1>


                            <p>
                                Algunos de los contenidos en nuestra plataforma no han sido creados y 
                                producidos por GUASASAPO, sino que corresponden a proyectos en los 
                                han participado miembrxs de la cooperativa, o han sido creados y 
                                desarrollados por personas de la cooperativa antes de que existiera 
                                o paralelamente fuera de ella.
                            </p>

                            <p>
                                Estos proyectos están bajo códigos de números romanos (G#XV), mientras que los desarrollados en y por la cooperativa están bajo números arábigos (G#15).
                            </p>
                            <p>
                            Todos los derechos de los primeros están reservados y protegidos, y el uso que la cooperativa fomenta de esta plataforma está referido solamente a los segundos.
                            </p>
                        </div>
                        <img src={next} className="cursormodalintro" onClick={() => nextStep(step + 1)}></img>

                    </div>
                    )
                    break;
            case 5:
                return setOpen(false)
        }
    })





    return (<div>{renderSwitch(step)}</div>)
}

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    backgroundImage: `url(${fondo})`,
    backgroundSize: 'cover',
    backgroundPositionX: "50%",
    display: "flex",
    alignItems: "center",
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    height: 500,
    backgroundColor: "#151515",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "25px",
    width: "fit-content",
  },
}));




export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
    useEffect(() => {
        setTimeout(function(){setOpen(true)}, 1000)
    }, [])

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(open);
  };
    return (
      <div className="ctnportada" onClick={handleOpen}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper} >
            <Introtv setOpen={setOpen}></Introtv>
          </div>
        </Modal>
      </div>
    );

}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ReactPlayer from "react-player";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AudioPlayer from 'react-h5-audio-player';
import Moment from 'moment'
import 'react-h5-audio-player/lib/styles.css';

import yt from "../img/yt.png";
import spotify from "../img/spotify.png";
import granada from "../img/granadal2.png";

import audio from "../test/test.mp3"
import escuchar from "../img/escuchar.png";

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: "#151515",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "25px",
        width: "fit-content",
    },
}));

export default function SimpleModal({ imagen, contenido, album, na, naa, ano, numcanciones, minutosalbum, spotifylink, youtubelink }) {
    const site = "https://api.guasasapo.cl" 

    const classes = useStyles();
    const googleURL = "https://docs.google.com/uc?export=download&id="
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const [sonando, setSonando] = React.useState("")
    const [nsonando, setNsonando] = React.useState(1)
        useEffect(() => {
            if (contenido.length > 0) {

            setSonando(googleURL + contenido[0].URL)
        }
        }, [contenido])
   
    
    const handleOpen = () => {
        setOpen(true);
        setNsonando(1);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const aSonar = (key) => {

        if (key != null) {
            setNsonando(key)
            let cancion = contenido.filter(i => i.numerocontenido === key)[0]
            setSonando(googleURL + cancion.URL)
        } else {
            setNsonando(nsonando + 1)
            let cancion = contenido.filter(i => i.numerocontenido === nsonando + 1)[0]
            setSonando(googleURL + cancion.URL)

        }


    }
    return (
        <div>
            {contenido.length > 0?
        <div className="ctnportada" onClick={handleOpen} style={contenido.length===0?{display:"none"}:null}>
            <img src={escuchar}></img>
            <p>ESCUCHAR</p>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <p onClick={handleClose} className="xmodal">
                        x
          </p>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div style={{ height: "100%" }}>
                            <div className="modalaudio">
                                <div className="sec1">
                                    <img src={imagen != undefined? site + imagen.url:null} height="300px" width="300px"></img>

                                    <div className="spotiyt">
                                        <a href={youtubelink} target="_blank"><img src={yt} /></a>
                                        <a href={spotifylink} target="_blank"><img src={spotify} /></a>

                                    </div>
                                    <div className="spotiyt spr">
                                        <a href="http://guasasapo.cl/granada" target="_blank"><img src={granada}></img></a></div>
                                </div>
                                <div>
                                    <h3><b>{naa}</b></h3>
                                    <h2>{na}</h2>
                                    <h3>{Moment(ano).format("YYYY")}<span className="ano sp12">{` ${numcanciones} Canciones / ${minutosalbum} Minutos`}</span></h3>
                                    <div className="canciones">
                                        {contenido.map(i => {
                                            return <p key={i.numerocontenido} onClick={() => aSonar(i.numerocontenido)} style={sonando == googleURL + i.URL ? { color: "#aca3c1", cursor: "pointer" } : { cursor: "pointer" }}>{`${i.numerocontenido}. ${i.nombre}`}</p>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <AudioPlayer
                                src={sonando}
                                autoPlay

                                onEnded={() => aSonar(null)}
                            />
                        </div>



                    </ClickAwayListener>
                </div>
            </Modal>
        </div>:null
        }
        </div>
    );
}

import React from 'react'
import { useState } from 'react'
import fondo from '../img/fondo.png'
import next from '../img/next.png'
import { Redirect } from 'react-router-dom'

const Introtv = () => {
    const [step, setStep] = useState(1)
    const nextStep = s => {
        setStep(s)
    }
    const renderSwitch = (step => {
        switch (step) {
            case 1:
                return (
                    <div className="itv">
                        <div className="itvb">
                            <h1>Acceso y uso</h1>


                            <p><b>GUASASAPOTV</b> es la plataforma multimedia de libre acceso y difusión de los proyectos y
                                contenido creativo que desarrolla la cooperativa GUASASAPO.</p>

                            <p>Se fomenta a bajar, piratear, contrabandear y disfrutar de nuestro contenido con completa libertad, salvo usufructo mercantil, comercial o de uso social que no comparta los principios y horizontes de nuestra cooperativa.</p>
                        </div>
                        <img src={next} className="cursor" onClick={() => nextStep(step + 1)}></img>
                    </div>
                )
                break;
            case 2:
                return (
                    <div className="itv">
                        <div className="itvb">
                            <h1>Funcionamiento y autogestión</h1>


                            <p>Todos los proyectos, al igual que todo lo que hacemos como cooperativa,
                            son iniciativas autogestionadas y autodeterminadas, sin fondos del Estado u
                                 organizaciones privadas.</p>

                            <p>Los proyectos se generan en nuestro espacio creativo <b>Taller de obra</b> que
                                 funciona con periodicidad semanal y el cual es un espacio de
                                 trabajo horizontal y colectivo.</p>
                            <p>Todos los recursos generados por medio de estos proyectos y
                                sus ítems derivados van en un 100% a autigestionar los proyectos venideros.</p>

                        </div>
                        <img src={next} className="cursor" onClick={() => nextStep(step + 1)}></img>

                    </div>
                )
                break;
            case 3:
                return (
                    <div className="itv">
                        <div className="itvb">
                            <h1>Cómo apoyar GUASASAPOTV</h1>


                            <p>Puedes apoyar el desarrollo de estos y otros proyectos,
                            como también el trabajo de nuestro cooperativismo, comprando productos de
                                la <b>Comedora GUASASAPO</b> y prefiriendo los servicios y productos de
                                 <b> Trincheta</b> espacio gráfico y editorial y de <b>Granada</b> estudio de grabación y postproducción de sonido.
                                 Todas secciones de producción de nuestra cooperativa.</p>

                            <p>También si deseas puedes hacer tu aporte voluntario depositando a nuestra cuenta cooperativa: para esto escríbenos a nuestro correo <a href="mailto:contacto@guasasapo.cl">contacto@guasasapo.cl</a></p>
                        </div>
                        <img src={next} className="cursor" onClick={() => nextStep(step + 1)}></img>

                    </div>
                )
                break;
            case 4:
                return <Redirect to='/guasasapotv' />
        }
    })





    return (<div className="introtv" style={{ backgroundImage: `url(${fondo})` }}>{renderSwitch(step)}</div>)
}

export default Introtv
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import trailer from "../img/trailer.png";
import play from "../img/play.png";
import colaboradores from "../img/colaboradores2.png";
import colaborador from "../img/colaboradores.png";
import next from "../img/next.png";

import ReactPlayer from "react-player";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function getModalStyle() {
  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: "#151515",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "25px",
    width: "fit-content",
  },
}));




export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    console.log(open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  if (props.tipo == "trailer") {
    return (
      <div className="ctnportada" onClick={handleOpen}>
        <img src={trailer}></img>
        <p>TRAILER</p>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <p onClick={handleClose} className="xmodal">
              x
            </p>
            <ClickAwayListener onClickAway={handleClickAway}>
              <ReactPlayer
                controls={true}
                url={props.t}
                config={{
                  vimeo: {
                    playerOptions: { playsinline: false, autoplay: true },
                  },
                }}
              />
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    );
  } else if (props.tipo == "play") {
    return (
      <div className="ctnportada" onClick={handleOpen}>
        <img src={play}></img>
        <p>PLAY</p>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <p onClick={handleClose} className="xmodal">
              x
            </p>
            <ClickAwayListener onClickAway={handleClickAway}>
              <ReactPlayer
                controls={true}
                url={props.t}
                config={{
                  vimeo: {
                    playerOptions: { playsinline: false, autoplay: true },
                  },
                }}
              />
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    );
  } else if (props.tipo == "colaboradores") {
    return (
      <div className="colaboradores" onClick={handleOpen}>
        <img src={colaboradores}></img>


        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <p onClick={handleClose} className="xmodal">
              x
            </p>

            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <div style={{ backgroundImage: `url(${colaborador})`, height: "700px", width: "500px", backgroundPositionX: "center", display: "flex" }}>
                  <div className="colabm">
                    <p>Si te ha gustado lo que hacemos y cómo lo hacemos,
                    y te interesaría participar de nuestros proyectos presentes y futuros,
                    te invitamos a conocer más e ingresar
  <a href="https://forms.gle/kBwmNxnJpruBZ1Mg6" target="_blank">{" "}aquí</a>.</p>
                  </div>

                </div>
              </div>
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    );
  } else if (props.tipo == "episodio") {
    return (
      <div className="cajaepisodio">
        <img src={props.img} onClick={handleOpen}></img>
        <h1 onClick={handleOpen}>{props.i.nombre} </h1>
        <h2>E{props.i.numeroepisodio}{" "}T{props.temp}{" "}<span style={{ color: "#e2e2e2" }}>{props.i.duracion} MINUTOS</span></h2>
        <p>{props.i.descripcion}</p>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={modalStyle} className={classes.paper}>
            <p onClick={handleClose} className="xmodal">
              x
            </p>

            <ClickAwayListener onClickAway={handleClickAway}>
              <ReactPlayer
                controls={true}
                url={props.t}
                config={{
                  vimeo: {
                    playerOptions: { playsinline: false, autoplay: true },
                  },
                }}
              />
            </ClickAwayListener>
          </div>
        </Modal>
      </div>
    )
  }

}

import React, { useState, useEffect } from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import Slider from "react-slick"
import ReactPlayer from 'react-player'
import PDFV from './PdfView'
import ReactImageMagnify from 'react-image-magnify';
import AudioPlayer from 'react-h5-audio-player';
import Moment from 'moment'
import 'react-h5-audio-player/lib/styles.css';

import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
const site = "https://api.guasasapo.cl";

const googleURL = "https://docs.google.com/uc?export=download&id="
const Galeria = ({ imagen, site, url, pdf, audiolink }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    console.log(imagen)
    console.log(site)
    console.log(audiolink)

    if (pdf != null) {
        return (

            <div>
                <PDFV pdf={pdf}>

                </PDFV>
            </div>
        )

    }
    if (url != null) {

        return (
            <ReactPlayer
                controls={true}
                url={url}
                config={{
                    vimeo: {
                        playerOptions: { playsinline: false, autoplay: true },
                    },
                }}
            />
        )
    } if (audiolink != null) {
        return (
            <div className="playergaleria">

                <AudioPlayer
                    src={googleURL + audiolink}
                    autoPlay

                />
            </div>

        )
    } else {
        return (

            <div className="imgsliderc">
                <Slider {...settings} style={{ color: "red", paddingLeft: "0" }}>
                    {imagen.map(i => {
                        console.log(i)
                        return (
                            <div className="imageContainer">
                                <InnerImageZoom zoomScale={0.8} src={site + i.url} alt={i.name} style={{ width: "100%" }}></InnerImageZoom>
                            </div>)
                    })}

                </Slider>
            </div>
        )
    }

}


function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: "#151515",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "25px",
        width: "fit-content",
        maxHeight: "100vh",
        maxWidth: "50vw",
    },
}));
function SimpleModal({ nombre, descripcion, imagenm, imagenc, url, pdf, audiolink }) {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClickAway = () => {
        setOpen(false);
    };

    return (

        <div className="cajaepisodio">
            <img src={site + imagenm} onClick={handleOpen}></img>
            <h1>{nombre}</h1>
            <p>{descripcion}</p>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <p onClick={handleClose} className="xmodal">
                        x
          </p>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <div>
                            <Galeria pdf={pdf} imagen={imagenc} nombre={nombre} site={site} url={url} audiolink={audiolink}></Galeria>
                        </div>
                    </ClickAwayListener>
                </div>
            </Modal>
        </div>
    );
}


export default SimpleModal

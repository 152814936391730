

import React, { useState, useMemo, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'



pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFV = ({ pdf }) => {
    const [page, setPage] = useState(1)
    const [zoom, setZoom] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const onDocumentLoadSuccess = ({ numPages }) => setTotalPages(numPages)
    console.log(pdf)

    return (
        <div className="cajapdf">
            <div className="containerpdf">
            <button onClick={() => page != 1 ? setPage(page - 1) : null} className="slick-arrow slick-prev pdfrow" style={{position: "fixed", left:"0"}}></button>


            <Document
                file={pdf}
                style={{paddingRight:"50px"}}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={page} scale={zoom} />

            </Document>
            <button onClick={() => page != totalPages ? setPage(page + 1) : null} className="slick-arrow slick-next pdfrow" style={{right:"0"}}></button>
            <a href={pdf} target="_blank"><div className="botondownload">DESCARGAR
            </div></a></div>
        </div>

    )
}

export default PDFV
import React, {useState} from 'react'
import compartir from "../../img/compartir.png";

import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

const Social = ({nombre}) => {
    const [compartira, setCompartira] = useState()
    const handleShare = (x) => {
        compartira === x ? setCompartira(false) : setCompartira(x);
    };
    return(
        <div className="ctnportada" onClick={() => handleShare(nombre)}>
                                <img alt="boton de comptarir" src={compartir}></img>
                                <p>COMPARTIR</p>
                                <div
                                    className="sharewindow"
                                    style={compartira ? null : { display: "none" }}
                                >
                                    <WhatsappShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                        separator=" - "
                                    >
                                        <WhatsappIcon round={true} size={40}></WhatsappIcon>
                                    </WhatsappShareButton>
                                    <FacebookShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        quote={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <FacebookIcon round={true} size={40}></FacebookIcon>
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <TwitterIcon round={true} size={40}></TwitterIcon>
                                    </TwitterShareButton>
                                    <EmailShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        subject={`${nombre} en GUASASAPOTV`}
                                        body={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <EmailIcon round={true} size={40}></EmailIcon>
                                    </EmailShareButton>
                                </div>
                            </div>
    )
}

export default Social
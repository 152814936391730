import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "moment";
import prev from "../img/prev.png";
import next from "../img/next.png";
import { CSSTransition } from "react-transition-group";
import trailer from "../img/trailer.png";
import compartir from "../img/compartir.png";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import ReactImageMagnify from 'react-image-magnify';


import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";


import SimpleModal from "./Galeria"
import GalModal from "./Modalimagen"
import ver from "../img/ver.png";




import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import { List } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: "#151515",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "25px",
        width: "fit-content",
    },
}));
const site = "https://api.guasasapo.cl";
const ItemSeries = ({
    nombre,
    subcategoria,
    codigo,
    masinfo,
    descpricion,
    imagenes,
    lanzamiento,
    imgdesc,
    duracion,
    URL,
    ciclos
}) => {

    const classes = useStyles();
    const [playing, setPlaying] = useState(false);
    const [compartira, setCompartira] = useState();
    const [masidata, setMasidata] = useState([]);
    const [tempS, setTempS] = useState([])
    const player = useRef(null);
    const [urlE, setUrlE] = useState();

    const [masi, setMasi] = useState(false);

    const [temp, setTemp] = React.useState(1);
    const sliderE = useRef();

    useEffect(() => {
        setMasidata([]);
        setPlaying(false);
        setMasi(false);
        setTemp(1)
        let asd2 = masinfo.split("\n");
        asd2.map((i) => {
            let letx = i.split(": ");
            console.log(letx[0]);
            setMasidata((masidata) => [...masidata, { [letx[0]]: letx[1] }]);
        });
        console.log(masidata);
        var ii = temp - 1
        if (ciclos.length>0){
            axios.get(`https://api.guasasapo.cl/ciclos/${ciclos[ii].id}`).then(function (res) {
                setTempS(res.data.contenidos);
                console.log("contenidos")
                console.log(res.data.contenidos);
            });
        }
        
    }, [nombre]);
    useEffect(() => {
        var ii = temp - 1
        if (ciclos.length>0){

        axios.get(`https://api.guasasapo.cl/ciclos/${ciclos[ii].id}`).then(function (res) {
            setTempS(res.data.contenidos);
            console.log("contenidos")
            console.log(res.data.contenidos);
        });
    }
    }, [temp]);
    const handleShare = (x) => {
        compartira == x ? setCompartira(false) : setCompartira(x);
    };
    const handleChange = (event) => {
        setTemp(event.target.value);
    };
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: tempS.length >= 5 ? 5 : tempS.length,
        draggable: false,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: tempS.length > 4 ? 4 : tempS.length,
                    slidesToScroll: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sNext = () => {
        sliderE.current.slickNext();
    };
    const sPrev = () => {
        sliderE.current.slickPrev();
    };
    return (
        <>
            <div className="pelidetalles" id="pelidetalles">
                <div className="info">
                    <div>
                        <div>
                            <h3>{subcategoria}</h3>
                            <div className={`linea Series`}></div>
                        </div>
                        <h1>{nombre}</h1>
                        <div className="spetalle">
                            <p>
                                <b>{codigo}</b> /{" "}
                                <span className="ano">{Moment(lanzamiento).format("YYYY")}</span>
                                <span className="ano" style={{ marginLeft: "10px" }}>
                                {duracion===null?null:`${duracion} MINUTOS`}              </span>
                            </p>
                        </div>
                        <p className="desc">{descpricion}</p>
                        <div
                            className="masinfo"

                        //onClick={() => setMasi(!masi)}
                        >
                            <p
                                onMouseEnter={() => setMasi(!masi)}
                                onMouseLeave={() => setMasi(!masi)}
                                onClick={() => console.log(tempS.length)}
                            >
                                + más info
            </p>
                        </div>
                        <div className="btnportada">
                            <GalModal imagen={imagenes}></GalModal>
                            {/* {imagenes.length>0 ? <div className="ctnportada" onClick={()=>console.log("asd")}>
                                <img src={ver}></img>
                                <p>VER</p>
                            </div> : null} */}

                            <div className="ctnportada" onClick={() => handleShare(nombre)}>
                                <img src={compartir}></img>
                                <p>COMPARTIR</p>
                                <div
                                    className="sharewindow"
                                    style={compartira ? null : { display: "none" }}
                                >
                                    <WhatsappShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                        separator=" - "
                                    >
                                        <WhatsappIcon round={true} size={40}></WhatsappIcon>
                                    </WhatsappShareButton>
                                    <FacebookShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        quote={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <FacebookIcon round={true} size={40}></FacebookIcon>
                                    </FacebookShareButton>
                                    <TwitterShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <TwitterIcon round={true} size={40}></TwitterIcon>
                                    </TwitterShareButton>
                                    <EmailShareButton
                                        url={"www.guasasapo.cl/guasasapotv"}
                                        subject={`${nombre} en GUASASAPOTV`}
                                        body={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    >
                                        <EmailIcon round={true} size={40}></EmailIcon>
                                    </EmailShareButton>
                                </div>
                            </div>


                        </div>
                        <ReactPlayer
                            playing={playing}
                            key={nombre + codigo}
                            ref={player}
                            autoplay
                            style={playing ? null : { display: "none" }}
                            config={{
                                vimeo: {
                                    playerOptions: { playsinline: false, autoplay: true },
                                },
                            }}
                            controls={true}
                            url={urlE}
                        />
                    </div>
                </div>

                <div
                    className="foto"
                    style={{
                        backgroundImage: `url(https://api.guasasapo.cl${imgdesc})`,
                    }}
                >
                    <CSSTransition className="fotoinfo" timeout={200} in={masi}>
                        <div>
                            <div className="fitext">
                                {masidata.map((i) => {
                                    return (
                                        <div className="fittxt">
                                            <p className="ano">{Object.keys(i)}:</p>
                                            <p>{Object.values(i)}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            </div>
            {ciclos.length>0?
            <div className="slidertemporadas">

                <FormControl className={classes.formControl}>

                    <Select
                        value={temp}
                        onChange={handleChange}
                        className={classes.selectEmpty + " se"}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >

                        {ciclos.map(i => {
                            return (<MenuItem value={i.numciclo}>CICLO {i.numciclo}</MenuItem>)
                        })}
                    </Select>
                </FormControl>
               
                <div className="flechita prev fep" onClick={() => sPrev()}>
                    <img src={prev}></img>
                </div>
                <div className="flechita next fep" onClick={() => sNext()}>
                    <img src={next}></img>
                </div>
                <Slider
                    {...settings}
                    className="sliderepisodios"
                    ref={sliderE}
                    style={{ height: "190px", marginLeft: "30px" }}
                >
                    {tempS.length > 1 ? tempS.map(i => {

                        return (
                            <SimpleModal imagenc={i.imagencontenido} imagenm={i.imagenmenu.url} nombre={i.nombre} descripcion={i.descripcion} url={i.URL} pdf={i.pdflink}></SimpleModal>
                        )
                    }) : null}
                </Slider>

            </div>
             :null}
        </>
    );
};

const Graficayfoto = () => {
    const [ser, setSer] = useState([]);
    const [s, setS] = useState();
    const sliderS = useRef();
    const graficaR = useRef();

    const scrollToRef = (ref) =>
        window.scrollTo(0, ref.current.offsetParent.offsetTop);
    useEffect(() => {
        axios.get(`https://api.guasasapo.cl/graficayfotografias?_sort=lanzamiento:DESC`).then(function (res) {
            setSer(res.data);
            console.log("GRAFICA Y FOTO")
            console.log(res.data);
        });
    }, []);
    const selectP = (i) => {
        setS(i);
        scrollToRef(graficaR);
    };
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: ser.length > 5 ? 5 : ser.length,
        draggable: false,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: ser.length > 5 ? 4 : ser.length,
                    slidesToScroll: 4,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sNext = () => {
        sliderS.current.slickNext();
    };
    const sPrev = () => {
        sliderS.current.slickPrev();
    };

    return (
        <div className="sliderpeliculas" id="Peliculas">
            <h1>Gráfica y Fotografía</h1>
            <div style={{ position: "relative" }}>
                <div className="flechita prev" onClick={() => sPrev()}>
                    <img src={prev}></img>
                </div>
                <div className="flechita next" onClick={() => sNext()}>
                    <img src={next}></img>
                </div>


                <Slider
                    {...settings}
                    ref={sliderS}
                    onInit={() => setTimeout(() => { sliderS.current.slickGoTo(0) }, 400)}

                >
                    {ser.map((i) => {
                        return (
                            <div className="peli">
                                <img
                                    alt={i.nombre}
                                    src={site + i.img.url}
                                    onClick={() => selectP(i)}
                                    key={i.codigo}
                                ></img>
                            </div>
                        );
                    })}
                </Slider>

            </div>
            <div ref={graficaR}>
                {s ? (
                    <CSSTransition
                        className="detallespeli"
                        timeout={200}
                        in={s}
                        appear={true}
                    >
                        <div>
                            <ItemSeries
                                nombre={s.nombre}
                                subcategoria={s.subcategoria}
                                codigo={s.codigo}
                                lanzamiento={s.lanzamiento}
                                URL={s.URL}
                                masinfo={s.masinfo}
                                descpricion={s.descripcion}
                                duracion={s.duracion}
                                imgdesc={s.img2descripcion.url}
                                ciclos={s.ciclos}
                                imagenes={s.imagenes}
                            ></ItemSeries>
                        </div>
                    </CSSTransition>
                ) : null}
            </div>
        </div>
    );
};

export default Graficayfoto;

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moment from "moment";
import prev from "../img/prev.png";
import next from "../img/next.png";
import { CSSTransition } from "react-transition-group";
import trailer from "../img/trailer.png";
import play from "../img/escuchar.png";
import compartir from "../img/compartir.png";
import {
    EmailShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
} from "react-share";
import {
    EmailIcon,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";

import SimpleModal from "./Modalaudio";

const ItemPeliculas = ({
    nombre,
    subcategoria,
    codigo,
    masinfo,
    descpricion,
    lanzamiento,
    imgdesc,
    duracion,
    ciclos,
    contenido,
    na,
    naa,
    minutosalbum,
    numcanciones,
    imagenalbum,
    URL,
    spotifylink,
    youtubelink
}) => {
    const [playing, setPlaying] = useState(false);
    const [compartira, setCompartira] = useState();
    const [masidata, setMasidata] = useState([]);
    const player = useRef(null);

    useEffect(() => {
        setMasidata([]);
        setPlaying(false);
        setMasi(false);

        let asd2 = masinfo.split("\n");
        asd2.map((i) => {
            let letx = i.split(": ");
            console.log(letx[0]);
            setMasidata((masidata) => [...masidata, { [letx[0]]: letx[1] }]);
        });
        console.log(ciclos);
    }, [nombre]);

    const playVideo = () => {
        setPlaying(true);
        console.log(player.current.wrapper);
        screenfull.request(player.current.wrapper);
    };
    document.addEventListener("fullscreenchange", onFullScreenChange, false);
    document.addEventListener(
        "webkitfullscreenchange",
        onFullScreenChange,
        false
    );
    document.addEventListener("mozfullscreenchange", onFullScreenChange, false);

    function onFullScreenChange() {
        var fullscreenElement =
            document.fullscreenElement ||
            document.mozFullScreenElement ||
            document.webkitFullscreenElement;
        console.log("asd");
        // if in fullscreen mode fullscreenElement won't be null
        if (fullscreenElement == null) {
            setPlaying(false);
        }
    }
    const handleShare = (x) => {
        compartira == x ? setCompartira(false) : setCompartira(x);
    };
    const [masi, setMasi] = useState(false);
    return (
        <div className="pelidetalles" id="pelidetalles">
            <div className="info">
                <div>
                    <div>
                        <h3>{subcategoria}</h3>
                        <div className={`linea Audioymusica`}></div>
                    </div>
                    <h1>{nombre}</h1>
                    <div className="spetalle">
                        <p>
                            <b>{codigo}</b> /{" "}
                            <span className="ano">{Moment(lanzamiento).format("YYYY")}</span>
                            <span className="ano" style={{ marginLeft: "10px" }}>
                            {duracion===null?null:`${duracion} MINUTOS`}              </span>
                        </p>
                    </div>
                    <p className="desc">{descpricion}</p>
                    <div
                        className="masinfo"

                    //onClick={() => setMasi(!masi)}
                    >
                        <p
                            onMouseEnter={() => setMasi(!masi)}
                            onMouseLeave={() => setMasi(!masi)}
                        >
                            + más info
            </p>
                    </div>
                    <div className="btnportada">
                        <SimpleModal
                            contenido={contenido}
                            imagen={imagenalbum}
                            album={nombre}
                            ano={lanzamiento}
                            ccancioens={lanzamiento}
                            cminutos={lanzamiento}
                            naa={naa}
                            na={na}
                            youtubelink={youtubelink}
                            spotifylink={spotifylink}
                            numcanciones={numcanciones}
                            minutosalbum={minutosalbum}
                        />



                        <div className="ctnportada" onClick={() => handleShare(nombre)}>
                            <img src={compartir}></img>
                            <p>COMPARTIR</p>
                            <div
                                className="sharewindow"
                                style={compartira ? null : { display: "none" }}
                            >
                                <WhatsappShareButton
                                    url={"www.guasasapo.cl/guasasapotv"}
                                    title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                    separator=" - "
                                >
                                    <WhatsappIcon round={true} size={40}></WhatsappIcon>
                                </WhatsappShareButton>
                                <FacebookShareButton
                                    url={"www.guasasapo.cl/guasasapotv"}
                                    quote={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                >
                                    <FacebookIcon round={true} size={40}></FacebookIcon>
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={"www.guasasapo.cl/guasasapotv"}
                                    title={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                >
                                    <TwitterIcon round={true} size={40}></TwitterIcon>
                                </TwitterShareButton>
                                <EmailShareButton
                                    url={"www.guasasapo.cl/guasasapotv"}
                                    subject={`${nombre} en GUASASAPOTV`}
                                    body={`¡De verdad recomiendo que vean ${nombre}! En GUASASAPOTV`}
                                >
                                    <EmailIcon round={true} size={40}></EmailIcon>
                                </EmailShareButton>
                            </div>
                        </div>

                    </div>
                    <ReactPlayer
                        playing={playing}
                        key={nombre + codigo}
                        ref={player}
                        style={playing ? null : { display: "none" }}
                        config={{
                            vimeo: {
                                playerOptions: { playsinline: false },
                            },
                        }}
                        controls={true}
                        url={URL}
                    />
                </div>
            </div>

            <div
                className="foto"
                style={{
                    backgroundImage: `url(https://api.guasasapo.cl${imgdesc})`,
                }}
            >
                <CSSTransition className="fotoinfo" timeout={200} in={masi}>
                    <div>
                        <div className="fitext">
                            {masidata.map((i) => {
                                return (
                                    <div className="fittxt">
                                        <p className="ano">{Object.keys(i)}:</p>
                                        <p>{Object.values(i)}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </CSSTransition>
            </div>
        </div>
    );
};

const Audioymusica = () => {
    const [pel, setPel] = useState([]);
    const [s, setS] = useState();
    const site = "https://api.guasasapo.cl";
    const sliderR = useRef();
    const peliR = useRef();

    const scrollToRef = (ref) =>
        window.scrollTo(0, ref.current.offsetParent.offsetTop);
    useEffect(() => {
        axios.get("https://api.guasasapo.cl/audioymusicas?_sort=lanzamiento:DESC").then(function (res) {
            setPel(res.data);
            console.log("AYM")
            console.log(res.data)
        });
    }, []);
    const selectP = (i) => {
        setS(i);
        scrollToRef(peliR);
    };
    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        draggable: false,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    infinite: true,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const sNext = () => {
        sliderR.current.slickNext();
    };
    const sPrev = () => {
        sliderR.current.slickPrev();
    };

    return (
        <div className="sliderpeliculas" id="Peliculas">
            <h1>Audio y Música</h1>
            <div style={{ position: "relative" }}>
                <div className="flechita prev" onClick={() => sPrev()}>
                    <img src={prev}></img>
                </div>
                <div className="flechita next" onClick={() => sNext()}>
                    <img src={next}></img>
                </div>
                <Slider
                    {...settings}
                    ref={sliderR}
                    onInit={() => setTimeout(() => { sliderR.current.slickGoTo(0) }, 400)}
                >
                    {pel.map((i) => {
                        return (
                            <div className="peli">
                                <img
                                    alt={i.nombre}
                                    src={site + i.img.url}
                                    onClick={() => selectP(i)}
                                    key={i.codigo}
                                ></img>
                            </div>
                        );
                    })}
                </Slider>
            </div>
            <div ref={peliR}>
                {s ? (
                    <CSSTransition
                        className="detallespeli"
                        timeout={200}
                        in={s}
                        appear={true}
                    >
                        <div>
                            <ItemPeliculas
                                nombre={s.nombre}
                                subcategoria={s.subcategoria}
                                codigo={s.codigo}
                                lanzamiento={s.lanzamiento}
                                URL={s.URL}
                                masinfo={s.masinfo}
                                descpricion={s.descripcion}
                                duracion={s.duracion}
                                imgdesc={s.img2descripcion.url}
                                contenido={s.contenidos}
                                imagenalbum={s.imagenalbum}
                                na={s.nombrealbum}
                                naa={s.nombreartistaalbum}
                                numcanciones={s.numerocanciones}
                                minutosalbum={s.minutosalbum}
                                youtubelink={s.youtubelink}
                                ciclos={s.ciclos}
                                spotifylink={s.spotifylink}
                            ></ItemPeliculas>
                        </div>
                    </CSSTransition>
                ) : null}
            </div>
        </div>
    );
};

export default Audioymusica;

import React from "react";
import ReactImageMagnify from 'react-image-magnify';


import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import InnerImageZoom from 'react-inner-image-zoom'
import Slider from "react-slick"


import ver from "../img/ver.png";

function getModalStyle() {
    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
    };
}


const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: "#151515",
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: "25px",
        width: "fit-content",
    },
}));
const site = "https://api.guasasapo.cl";

export default function SimpleModal(props) {
    let imagencc
    console.log(props.imagen)
    if (typeof props.imagen === "undefined"){
        imagencc = []   
    }else{
        imagencc = props.imagen
    }
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        console.log(open);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            { imagencc.length > 0?
        <div className="ctnportada" onClick={handleOpen}>
            
            <img src={ver}></img>
            <p>VER</p>
           
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    <p onClick={handleClose} className="xmodal">
                        x
          </p>
                    <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="imgsliderc">
                <Slider {...settings} style={{ color: "red", paddingLeft: "0" }}>
                    {props.imagen != undefined ?props.imagen.map(i => {
                        return (
                            <div className="imageContainer">
                                <InnerImageZoom zoomScale={0.8} src={site + i.url} alt={i.name} style={{ width: "100%" }}></InnerImageZoom>
                            </div>)
                    }):null}

                </Slider>
            </div>

                    </ClickAwayListener>
                </div>
            </Modal>
            </div>:null }
        </div>
    );
}
